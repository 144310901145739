const scs = {
  "1.1.1": {
    0: "non-text-content",
    1: "A",
  },
  "1.2.1": {
    0: "audio-only-and-video-only-prerecorded",
    1: "A",
  },
  "1.2.2": {
    0: "captions-prerecorded",
    1: "A",
  },
  "1.2.3": {
    0: "audio-description-or-media-alternative-prerecorded",
    1: "A",
  },
  "1.2.8": {
    0: "media-alternative-prerecorded",
    1: "AAA",
  },
  "1.3.1": {
    0: "info-and-relationships",
    1: "A",
  },
  "1.3.2": {
    0: "meaningful-sequence",
    1: "A",
  },
  "1.3.4": {
    0: "orientation",
    1: "AA",
  },
  "1.4.3": {
    0: "contrast-minimum",
    1: "AA",
  },
  "1.4.4": {
    0: "resize-text",
    1: "AA",
  },
  "1.4.5": {
    0: "images-of-text",
    1: "AA",
  },
  "1.4.6": {
    0: "contrast-enhanced",
    1: "AAA",
  },
  "1.4.8": {
    0: "visual-presentation",
    1: "AAA",
  },
  "1.4.9": {
    0: "images-of-text-no-exception",
    1: "AAA",
  },
  "1.4.12": {
    0: "text-spacing",
    1: "AA",
  },
  "2.1.1": {
    0: "keyboard",
    1: "A",
  },
  "2.1.3": {
    0: "keyboard-no-exception",
    1: "AAA",
  },
  "2.2.1": {
    0: "timing-adjustable",
    1: "A",
  },
  "2.2.2": {
    0: "pause-stop-hide",
    1: "A",
  },
  "2.2.4": {
    0: "interruptions",
    1: "AAA",
  },
  "2.4.1": {
    0: "bypass-blocks",
    1: "A",
  },
  "2.4.2": {
    0: "navigation-mechanisms-title",
    1: "A",
  },
  "2.4.4": {
    0: "link-purpose-in-context",
    1: "A",
  },
  "2.4.5": {
    0: "multiple-ways",
    1: "AA",
  },
  "2.4.6": {
    0: "headings-and-labels",
    1: "AA",
  },
  "2.4.7": {
    0: "focus-visible",
    1: "AA",
  },
  "2.4.8": {
    0: "location",
    1: "AAA",
  },
  "2.4.9": {
    0: "link-purpose-link-only",
    1: "AAA",
  },
  "2.4.10": {
    0: "section-headings",
    1: "AAA",
  },
  "2.5.3": {
    0: "label-in-name",
    1: "AAA",
  },
  "3.1.1": {
    0: "language-of-page",
    1: "A",
  },
  "3.1.2": {
    0: "language-of-parts",
    1: "A",
  },
  "3.1.3": {
    0: "unusual-words",
    1: "AAA",
  },
  "3.1.4": {
    0: "abbreviations",
    1: "AAA",
  },
  "3.2.1": {
    0: "on-focus",
    1: "A",
  },
  "3.2.2": {
    0: "on-input",
    1: "A",
  },
  "3.2.5": {
    0: "change-on-request",
    1: "AAA",
  },
  "3.3.2": {
    0: "labels-or-instructions",
    1: "A",
  },
  "4.1.1": {
    0: "parsing",
    1: "A",
  },
  "4.1.2": {
    0: "name-role-value",
    1: "A",
  },
};

export default scs;
